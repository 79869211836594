.contacts_block {
	margin-bottom: 6.2rem;

	&:last-child {
		margin-bottom: 0;
	}

	@include bp($point_4 - 1) {
		margin-bottom: 7.4rem;
	}
}

.contacts_block_title {
	margin-bottom: 3rem;

	color: $blue;
	font-size: 1.5rem;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
	text-align: center;
}

.icon_block_list {
	@extend %global_flex_block_row_wrap_center;

	margin: 0 -1rem -4rem;

	&.v1_mod {
		@include bp($point_2, min) {
			margin-bottom: -10em;
		}
		@include bp($point_3) {
			margin-bottom: -2em;
		}
	}

	&.v2_mod {
		@include bp($point_4, min) {
			margin: 0 -6em -8em 0;
		}

		@include bp($point_4 - 1) {
			margin-bottom: -1.6em;
		}
	}
}

.icon_block_item {
	width: 25%;
	padding: 0 1rem 4rem;
	text-align: center;

	&.size_2_mod {
		@include bp($point_1, min) {
			width: 20%;
		}

		@media (min-width: $point_2 + 1) and (max-width: $point_1) {
			width: 25%;
		}

		@include bp($point_2, min) {
			padding-bottom: 10em;
		}

		@include bp($point_3) {
			width: 33.33%;
			padding-bottom: 2em;
		}
	}

	&.size_3_mod {
		@include bp($point_4, min) {
			width: 25%;
			padding: 0 6em 8em 0;
		}

		@include bp($point_4 - 1) {
			padding-bottom: 1.6em;
		}
	}

	@include bp($point_4 - 1) {
		width: 50%;
	}
}

.icon_block_item_link {
	@extend %transition_opacity;

	width: 100%;
	display: block;

	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		opacity: 0.8;
		text-decoration: none;
	}
}

.icon_block_img_wrap {
	margin: 0 auto 1.8rem;
	width: 11rem;
	height: 11rem;

	&:last-child {
		margin-bottom: 0;
	}

	@include bp($point_4 - 1) {
		width: 8rem;
		height: 8rem;
	}
}

.icon_block_img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.icon_block_title {
	margin-bottom: 1rem;

	font-size: 1.5rem;
	font-weight: 700;
	color: $blue;
	text-transform: uppercase;

	.size_2_mod & {
		@include bp($point_3) {
			font-size: 1.1em;
			margin-bottom: 0;
		}
	}
}

.icon_block_link {
	font-size: 1.5rem;
	color: $blue;
	transition: color 0.3s;

	&:hover,
	&:focus {
		color: $yellow_3;
		text-decoration: none;
	}
}

.icon_block_text {
	font-size: 1.5rem;
	color: $blue;
}

.map {
	height: 64rem;
}
