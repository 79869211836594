.scroll_block {
	@extend %global_flex_block_row_wrap_flex-start;

	width: 100%;

	@include bp($point_1) {
		flex-direction: column;
		align-items: center;
		align-content: center;
	}
}

.scroll_block_icon_w {
	width: 22em;
	flex: none;

	@include bp($point_1 + 1, min) {
		margin-right: 1em;
	}

	@include bp($point_1) {
		margin-bottom: 5em;
	}
}

.scroll_block_icon {
	margin: 0 auto 1.8em;

	@include bp($point_2 + 1, min) {
		width: 11em;
		height: 11em;
	}

	@include bp($point_2) {
		width: 8em;
		height: 8em;
	}
}

.scroll_block_icon_img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.scroll_block_icon_title {
	width: 100%;
	text-align: center;

	font-size: 1.5em;
	font-weight: 700;
	color: $blue;
	text-transform: uppercase;

	.title_mod & {
		font-weight: 900;
		font-size: 1.8em;
	}

	.red_mod & {
		color: $red;
	}

	.blue_mod & {
		color: $blue_2;
	}

	.yellow_mod & {
		color: $yellow_3;
	}
}

.scroll_block_content_w {
	width: 100%;
	max-width: 76em;

	@include bp($point_1 + 1, min) {
		margin-left: auto;
	}
}

.scroll_block_content {
	width: 100%;
	margin-bottom: (4.4em/1.5);

	color: $blue;
	font-size: 1.5em;
	font-weight: 400;
	line-height: (2.2/1.5);

	&:last-child {
		margin-bottom: 0;
	}

	h3 {
		margin-bottom: (2.8em/1.5);

		font-weight: 700;

		&:last-child {
			margin-bottom: 0;
		}
	}

	p {
		margin-bottom: (2.8em/1.5);

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul {
		margin-bottom: (2.8em/1.5);

		&:last-child {
			margin-bottom: 0;
		}
	}

	li:not(.v1_mod) {
		position: relative;
		padding-left: (2.4em/1.5);

		&:before {
			position: absolute;
			top: 0;
			left: 0;

			content: '-';
		}
	}
}

.itallic_mod {
	font-style: italic;
}

.info_block_w {
	@extend %global_flex_block_row_wrap_flex-start;

	width: 100%;
}

.info_col {
	@include bp($point_2 + 1, min) {
		width: 33.33%;
		padding-right: 2em;
	}

	@include bp($point_2) {
		width: 100%;
		margin-bottom: 2em;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
