.footer {
	@extend %global_flex_block_row_nowrap_flex-start_center;

	width: 100%;
	min-height: $height_footer;
	flex: 0 0 auto;
	padding: 2rem;

	font-size: 1rem;

	background-color: $blue;

	will-change: transform;

	@include bp($point_4 - 1) {
		min-height: 10rem;
	}
}

.footer_title {
	font-size: 1.5rem;
	font-weight: 700;
	color: $white;
	text-align: center;
}