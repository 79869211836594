.btn_base {
	@extend %blue_btn_1;
	@extend %btn_size_1;
}

.btn_list {
	@extend %global_flex_block_row_nowrap_center;

	@include bp($point_4 - 1) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
}

.btn_item {
	& + & {
		@include bp($point_4, min) {
			margin-left: 9rem;
		}

		@include bp($point_4 - 1) {
			margin-top: 3rem;
		}
	}
}

// decor

.section_decor {
	position: absolute;
	top: 0;
	left: 50%;

	width: 100%;
	min-width: 100rem;
	height: auto;
	margin-top: -9vw;

	transform: translateX(-50%);
	opacity: 0.05;

	z-index: -1;

	&.center_mod {
		margin-top: 0;
		top: 50%;
		transform: translate(-50%, -50%);

		@include bp($point_3) {
			opacity: .2;
			min-width: 115rem;
			transform: translate(-50%, -45%) rotate(-90deg);
		}
	}

	@include bp($point_4 - 1) {
		margin-top: -5rem;
	}
}

.section_decor_img {
	display: block;
	width: 100%;
}

// gallery

.gallery_list {
	@extend %global_flex_block_row_wrap_flex-start;
	margin-right: -3rem;
	margin-bottom: -1rem;

	@include bp($point_3) {
		margin-right: -2rem;
		margin-left: -2rem;
	}
}

.gallery_item {
	width: 50%;
	padding-right: 3rem;
	padding-bottom: 1rem;

	&:first-child {
		@include bp($point_2, min) {
			margin-top: 3rem;
		}
	}

	@include bp($point_3) {
		width: 100%;
		padding-right: 0;
	}
}

.gallery_img_wrap {
	position: relative;

	&:before {
		content: '';
		display: block;
		padding-bottom: 57.5%;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(136deg, #ffdf2b 0%, $blue_2 100%);
		opacity: 0.6;
	}
}

.gallery_img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

// index menu

.index_nav_item {
	position: absolute;
	top: 50%;
	left: 50%;

	display: inline-flex;

	&.v1_mod {
		margin-top: -34em;
		margin-left: -50em;

		@include bp($point_3) {
			margin-top: -21em;
			margin-left: -15em;
		}
	}

	&.v2_mod {
		margin-top: -34em;
		margin-left: 4em;

		@include bp($point_3) {
			margin-top: -21em;
			margin-left: 3em;
		}
	}

	&.v3_mod {
		margin-top: 20em;
		margin-left: -48.6em;

		@include bp($point_3) {
			margin-top: 8.4em;
			margin-left: -15em;
		}
	}

	&.v4_mod {
		margin-top: 0;
		margin-left: 31em;

		@include bp($point_3) {
			margin-top: 8.4em;
			margin-left: 3em;
		}
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.index_nav_bg {
	width: 17em;
	height: 17em;
	color: $blue_2;
	transition: color 0.3s;

	transform-origin: 50% 50%;

	.index_nav_item.bg_v1_mod & {
		color: $blue_2;
		transform: rotate(-45deg);
	}

	.index_nav_item.bg_v2_mod & {
		color: $red;
		transform: rotate(45deg);
	}

	.index_nav_item.bg_v3_mod & {
		color: $white_2;
		transform: rotate(135deg);
	}

	.index_nav_item.bg_v4_mod & {
		color: $blue;
		transform: rotate(135deg);
	}

	.index_nav_item:hover &,
	.index_nav_item:focus & {
		color: $blue;
	}

	.index_nav_item.bg_v4_mod:hover &,
	.index_nav_item.bg_v4_mod:focus & {
		color: $blue_2;
	}

	@include bp($point_3) {
		width: 12em;
		height: 12em;
	}
}

.index_nav_title {
	position: absolute;
	top: 50%;
	left: 0;

	width: 100%;

	font-size: 1.8em;
	font-weight: 700;
	color: $yellow_3;
	text-transform: uppercase;
	text-align: center;

	transform: translateY(-50%);
	transition: color 0.3s;

	.index_nav_item.bg_v1_mod & {
		color: $blue;
	}

	.index_nav_item.bg_v1_mod:hover &,
	.index_nav_item.bg_v1_mod:focus & {
		color: $yellow_3;
	}

	.index_nav_item.bg_v4_mod:hover &,
	.index_nav_item.bg_v4_mod:focus & {
		color: $blue;
	}

	@include bp($point_3) {
		font-size: 1.4em;
	}
}

.btn_w {
	width: 100%;

	&.center_content_mod {
		@extend %global_flex_block_row_wrap_center;
	}
}
