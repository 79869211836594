//---------------------------------------------fonts
$font_path: '../fonts/';
$font_1: 'Montserrat', sans-serif;
$font_size_base: 1.6rem;
//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #fff;
$black: #000;

$white_2: #efefef;

$yellow: #ffba12;
$yellow_2: #efca0f;
$yellow_3: #ffbb13;

$blue: #414b96;
$blue_2: #b9d0ef;

$red: #ed2a3c;

$color_text_base: $black;
$color_link_base: #4aa3df;
$body_bg: $white;
//---------------------------------------------colors###

//---------------------------------------------z-index
$z-index: (
	popap: 9999,
	header: 100,
	footer: 100,
);
//---------------------------------------------z-index###

//---------------------------------------------layout
$height_header: 6.6rem;
$page_width: 120rem;
$height_footer: 18rem;
$page_width: 117.2rem;
$page_width_2: 132rem;
$page_width_3: 102rem;

//---------------------------------------------layout###

//---------------------------------------------global values
$gl_indent: 2rem;
$gl_radius: 3rem;
//---------------------------------------------global values###

//---------------------------------------------media points
$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;
//---------------------------------------------media points

//---------------------------------------------forms
$input_height: 4rem;
$input_offset: 0 2rem;
$input_font_size: $font_size_base;
$input_border_color: #ccc;
$input_border_color_active: $black;
$input_placeholder_color: $black;
$input_text_color: $black;
$input_radius: $gl_radius;
//---------------------------------------------forms###
