.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-check {
	font-size:(32/10)*1rem;
	width:(32/32)*1em;
}
.icon-circle {
	font-size:(110/10)*1rem;
	width:(110/110)*1em;
}
.icon-empty {
	font-size:(45.44/10)*1rem;
	width:(45.44/45.44)*1em;
}
