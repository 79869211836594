//------------------------------------------------------------layout
.header {
	position: fixed;
	top: 0;
	left: 0;

	height: $height_header;
	width: 100%;
	padding: 0 $gl_indent;

	font-size: 1rem;

	background-color: $yellow_3;

	will-change: transform;

	@include z-index(header);
}

.header_in {
	@extend %global_flex_block_row_wrap_flex-start_center;

	width: 100%;
	height: 100%;
	max-width: $page_width_2;
	margin: 0 auto;
}

.logo {
	display: block;
	width: 47.4em;

	&.index_mod {
		position: relative;

		@include bp($point_3) {
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;

				width: 51.6rem;
				height: 51.6rem;
				margin-top: -25.8rem;
				margin-left: -25.8rem;

				background-color: $yellow_3;
				border-radius: 50%;
				z-index: -1;
			}
		}
	}

	@include bp($point_3) {
		width: 29em;
	}
}

.header_logo {
	display: block;
	width: 21.4em;

	text-decoration: none;

	@include bp($point_4 - 1) {
		width: 19.4rem;
	}
}

.header_logo_img {
	display: block;
	width: 100%;
	height: auto;
}

.header_nav {
	margin-left: auto;

	@include bp($point_4 - 1) {
		position: fixed;
		top: 6.6rem;
		left: 0;

		display: flex;
		width: 100%;
		height: calc(100vh - 6.6rem);
		padding: 0 2rem 6.6rem;

		overflow-y: auto;
		background-color: $yellow_3;

		opacity: 0;
		pointer-events: none;

		transition: opacity .3s;

		body.menu_open & {
			opacity: 1;
			pointer-events: initial;
		}
	}
}

.header_nav_list {
	@extend %global_flex_block_row_wrap_flex-start;

	list-style: none;
	margin: 0 -3em -1em 0;
	padding: 0;

	@include bp($point_4 - 1) {
		display: block;
		margin: auto;

		text-align: center;
	}
}

.header_nav_item {
	padding: 0 3em 1em 0;

	@include bp($point_4 - 1) {
		padding: 0;

		& + & {
			margin-top: 5rem;
		}
	}
}

.header_nav_link {
	@extend %transition_color;

	color: $blue;
	font-size: 1.2em;
	font-weight: 700;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		color: $white;
		text-decoration: none;
	}

	@include bp($point_4 - 1) {
		font-size: 3rem;
		font-weight: 900;
	}
}

//------------------------------------------------------------layout###


//---------------------------------------------trigger
@mixin user_menu_butt_line {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: .4rem;
	margin-top: -.2rem;
	background: $blue;
}

.menu_trigger {
	z-index: 210;
	position: relative;
	display: none; // hidden on wide screens
	height: 2rem;
	width: 3.4rem;
	margin-left: auto;
	text-transform: uppercase;

	color: transparent;
	cursor: pointer;

	@include bp($point_4 - 1) {
		display: block; // appear on tablets
	}

	&:before {
		content: '';
		transform: translate(0, -.8rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&:after {
		content: '';
		transform: translate(0, .8rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&.active_mod {
		&:before {
			transform: (rotate(45deg) translate(0, 0));
		}

		&:after {
			transform: (rotate(-45deg) translate(0, 0));
		}
	}
}

.menu_trigger_decor {
	transition: all .3s ease;

	.active_mod & {
		opacity: 0;
		transform: translate(100%, 0);
	}

	@include user_menu_butt_line;
}

//---------------------------------------------trigger###
