//---------------------------------------------sections
.section {
	position: relative;

	width: 100%;

	@include bp($point_2 + 1, min) {
		padding: 8em $gl_indent;
	}

	@include bp($point_2) {
		padding: 6em $gl_indent;
	}

	font-size: 1rem;

	overflow: hidden;

	&.bg_mod {
		background-color: #fafafa;
	}

	&.index_mod {
		@extend %global_flex_block_row_nowrap_center_center;
		height: 100vh;
		// padding: 51rem $gl_indent;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $yellow_3;
			z-index: -2;
		}

		@media (max-height: 800px) and (min-width: 1024px) {
			font-size: 1.3vh;
		}

		@media (max-height: 440px) {
			font-size: 2.1vh;
		}
	}

	&.no_offset_mod {
		padding: 0;
	}
}

.section_in {
	max-width: $page_width;
	width: 100%;
	margin: 0 auto;

	&.size_mod {
		max-width: $page_width_3;
	}

	&.size_2_mod {
		max-width: 62em;
	}

	&.v2_mod {
		max-width: 59rem;
	}

	&.offset_mod {
		margin-bottom: 10rem;

		@include bp($point_4 - 1) {
			margin-bottom: 6rem;
		}
	}
}

.section_title {
	color: $blue;
	font-size: 4.8em;
	font-weight: 900;
	text-transform: uppercase;
	line-height: 1;

	&.offset_mod {
		margin-bottom: (4.4em/4.8);
	}

	&.center_text_mod {
		text-align: center;
	}

	&.center_mod {
		text-align: center;
	}

	&.index_mod {
		font-size: 4.9em;
		text-align: center;
		text-transform: initial;

		@include bp($point_3) {
			font-size: 3rem;
		}
	}

	@include bp($point_4 - 1) {
		font-size: 3rem;
	}
}

.section_subtitle {
	width: 100%;

	color: $blue;
	font-size: 1.2em;
	font-weight: 700;
	letter-spacing: 0.1rem;
	line-height: (2.4/1.2);
	text-transform: uppercase;

	&.center_text_mod {
		text-align: center;
	}

	&.offset_mod {
		margin-bottom: (3em/1.2);
	}
}

.section_descr {
	font-size: 1.5rem;
	line-height: 1.6;
	color: $blue;

	span {
		font-weight: 700;
	}

	&.offset_mod {
		margin-bottom: 7.8rem;

		@include bp($point_4 - 1) {
			margin-bottom: 3.2rem;
		}
	}
}
