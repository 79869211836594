@mixin st_btn() {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	white-space: nowrap;
	cursor: pointer;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

//color extends
%blue_btn_1 {
	font-weight: 900;
	color: $blue_2;
	background: transparent;
	border-radius: $gl_radius;
	border: 0.4rem solid $blue_2;
	transition: 0.3s ease;

	@include st_btn();

	&:hover {
		color: $yellow_3;
		border-color: $yellow_3;
	}
}

//size extends
%btn_size_1 {
	height: 5rem;
	width: 19rem;
	padding: 0 2rem;

	line-height: 5rem;
	font-size: 1.5em;

	border-radius: $gl_radius;
}
